<template>
    <v-app>
        <v-container class="pa-4" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="8">
                    <v-sheet :width="1000" class="opa elevation-1 pa-3 ma-2">
                        <h1 class="primary--text"><v-icon size="30" class="pa-2" color="primary">mdi-information</v-icon>A
                            propos de
                            Cartovia</h1>
                        <v-container>

                            <p>
                                <i class="pa-3">La plateforme Cartovia est une solution SaaS innovante et collaborative pour
                                    la
                                    gestion et
                                    la visualisation de données géolocalisées. Elle permet d’importer vos fichiers
                                    d’iventaires
                                    de manière simple et flexible, de visualiser vos données à travers notre cartographgie
                                    avancée et customisable, puis d’exporter le rendu sous format image ou par e-mail.</i>
                            </p>


                            <h3><v-icon class="pa-2" color="primary">mdi-star-circle-outline</v-icon>Quelques
                                fonctionnalités clés :</h3>

                            <ul class="custom-list">
                                <li>
                                    <b>Importation de Données Polyvalente:</b> Cartovia accepte une variété de
                                    formats de données,
                                    y compris Excel, CSV, JSON et GeoJSON. Cette flexibilité facilite l'intégration de
                                    divers
                                    ensembles de données géolocalisées.
                                </li>

                                <li>
                                    <b>Cartographie Intuitive et Customisable:</b> La plateforme offre une visualisation
                                    claire et
                                    simple des données sur des cartes personnalisables. Cette caractéristique est cruciale
                                    pour
                                    l'analyse spatiale et la prise de décision basée sur la localisation.
                                </li>

                                <li>
                                    <b>Filtres multiples selon le Profil Utilisateur:</b> Cartovia propose des filtres
                                    adaptés aux
                                    besoins spécifiques de différents utilisateurs, comme les agriculteurs, les
                                    viticulteurs,
                                    les forestiers et les professionnels urbains. Cela permet une analyse ciblée et
                                    pertinente
                                    selon le domaine d'application.
                                </li>

                                <li>
                                    <b>Exportation et Partage Facilités:</b> Les utilisateurs peuvent exporter leurs
                                    résultats sous
                                    forme d'images, ce qui est pratique pour la présentation et le reporting. De plus, la
                                    plateforme supporte le partage d'inventaires au sein d'équipes, permettant une
                                    collaboration
                                    efficace entre différents comptes.
                                </li>
                            </ul>

                            <h3><v-icon class="pa-2" color="primary">mdi-chat-question</v-icon>Comment ?</h3>

                            <ul>
                                <li>
                                    <b>Création de compte et connexion:</b> Les utilisateurs commencent par créer un compte
                                    sur
                                    Cartovia et se connectent à la plateforme.
                                </li>
                                <li>
                                    <b>Importation des Données :</b>
                                    Sélectionnez l'option d'importation des données.
                                    Téléchargez vos fichiers d'inventaire dans les formats pris en charge (Excel, CSV, JSON,
                                    GeoJSON).
                                    La plateforme traitera et intégrera ces données dans son système.
                                </li>

                                <li>
                                    <b>Organisation et Gestion des Données :</b>
                                    Une fois les données importées, organisez-les selon vos besoins.
                                    Vous pouvez créer des filtres selon vos données et organiser vos informations
                                    pertinentes
                                    sur la cartographie.
                                </li>

                                <li>
                                    <b>Utilisation de la Cartographie :</b>
                                    Accédez à l'outil de cartographie.
                                    Sélectionnez les données que vous souhaitez visualiser sur la carte.
                                    Utilisez les fonctionnalités de la carte pour personnaliser votre visualisation (zoom,
                                    déplacement, etc.).
                                </li>

                                <li>
                                    <b>Application des Filtres et Analyses :</b>
                                    Appliquez des filtres personnalisés selon votre profil (agriculteur, viticulteur,
                                    forestier,
                                    professionnel urbain, etc.) pour affiner l'analyse des données.
                                    Utilisez les outils d'analyse spatiale disponibles pour interpréter les données de
                                    manière
                                    plus approfondie.
                                </li>

                                <li>
                                    <b>Collaboration et Partage :</b>
                                    Partagez vos cartes et données avec d'autres membres de votre équipe.
                                    Collaborez sur les mêmes données en temps réel, si la plateforme le permet.
                                </li>

                                <li>
                                    <b>Exportation des Résultats :</b>
                                    Une fois votre analyse terminée, vous pouvez exporter les visualisations de cartes.
                                    Choisissez de les exporter sous forme d'images ou de les envoyer par e-mail pour le
                                    reporting ou la présentation.
                                </li>

                                <li>
                                    <b>Gestion de Compte et Paramètres :</b>
                                    Gérez les paramètres de votre compte, y compris les préférences de visualisation, les
                                    notifications, et les options de sécurité.
                                </li>

                                <li>
                                    <b>Assistance et Support :</b>
                                    En cas de besoin, utilisez les ressources d'aide et de support fournies par Cartovia
                                    pour résoudre les problèmes ou pour apprendre à utiliser de nouvelles fonctionnalités.
                                </li>
                            </ul>


                            <h3><v-icon class="pa-2" color="primary">mdi-account-group</v-icon> Pour qui ?</h3>

                            <ul>
                                <li>
                                    Professionnels de l'Agriculture et de la Viticulture : Agriculteurs et viticulteurs
                                    peuvent utiliser Cartovia pour cartographier et analyser leurs terres, cultures, et
                                    récoltes.
                                    Les filtres personnalisés et les visualisations cartographiques peuvent aider à
                                    optimiser la
                                    gestion des ressources et à planifier des stratégies agricoles efficaces.
                                </li>

                                <li>

                                    Gestionnaires Forestiers : Pour ceux qui travaillent dans la foresterie, Cartovia offre
                                    des
                                    outils pour surveiller et gérer les ressources forestières. La cartographie détaillée et
                                    les
                                    analyses spatiales peuvent aider à planifier les coupes, la conservation, et la gestion
                                    durable des forêts.
                                </li>

                                <li>
                                    Professionnels Urbains et Planificateurs : Les urbanistes, architectes, et autres
                                    professionnels urbains peuvent trouver dans Cartovia un outil précieux pour la
                                    planification
                                    spatiale, la gestion des infrastructures, et l'analyse urbaine.
                                </li>

                                <li>
                                    Équipes Collaboratives et Intersectorielles : La capacité de Cartovia à faciliter le
                                    partage
                                    et la collaboration sur des inventaires géolocalisés la rend utile pour les équipes
                                    travaillant sur des projets interdisciplinaires ou intersectoriels, tels que les projets
                                    de
                                    développement urbain, les initiatives environnementales, et les projets de recherche.
                                </li>

                                <li>
                                    Analystes et Décideurs : Les fonctionnalités de Cartovia en matière d'importation de
                                    données
                                    polyvalente, de cartographie intuitive, et de filtres personnalisés en font un outil
                                    précieux pour les analystes et les décideurs qui s'appuient sur des données
                                    géolocalisées
                                    pour informer leurs stratégies et décisions.
                                </li>

                                <li>
                                    Cartovia est particulièrement adaptée aux professionnels et aux équipes qui dépendent de
                                    données géospatiales précises pour leur travail quotidien, offrant une plateforme
                                    complète
                                    pour la gestion, l'analyse, et la visualisation de ces données.
                                </li>
                            </ul>
                        </v-container>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>


<script>
import xhr from "../plugins/axios";

export default {
    name: "Faq",
    data() {
        return {
            items: [
                {
                    title: 'Groupe 1',
                    active: false,
                    questions: [
                        {
                            question: 'Question 1.1?',
                            answer: 'Réponse à la question 1.1.'
                        },
                        {
                            question: 'Question 1.2?',
                            answer: 'Réponse à la question 1.2.'
                        },
                        {
                            question: 'Question 1.3?',
                            answer: 'Réponse à la question 1.3.'
                        },
                    ]
                },
                {
                    title: 'Groupe 2',
                    active: false,
                    questions: [
                        {
                            question: 'Question 2.1?',
                            answer: 'Réponse à la question 2.1.'
                        },
                        {
                            question: 'Question 2.2?',
                            answer: 'Réponse à la question 2.2.'
                        },
                        {
                            question: 'Question 2.3?',
                            answer: 'Réponse à la question 2.3.'
                        },
                    ]
                },
                // Ajoutez d'autres groupes et questions si nécessaire
            ]
        }
    },
    methods: {

    }
};
</script>

<style scoped>
h3 {
    margin-bottom: 15px;
}

h3::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin-left: 20px;
}

ul li {
    position: relative;
    padding-left: 25px;
    margin: 10px;
}

ul li:before {
    content: '☑️';
    /* Symbole de vérification */
    position: absolute;
    left: 0;
}
</style>